<template>
    <div style="background:#f8fafc">
        <van-image height="14rem" fit="cover" lazy-load src="https://khome2.tuzuu.com/klfront/banner_me.png?v=2">
            <template v-slot:loading>
                <van-loading type="spinner" size="20" />
            </template>
        </van-image>

        <van-row class="user-links" type="flex" justify="center">
            <van-col span="8" is-link>
                <router-link to="/cashin?active=1" class="link">
                    <div class="shouyi allcash">¥{{list.allcash}}</div>
                    累计收益
                </router-link>
            </van-col>
            <van-col span="8">
                <router-link to="/cashin?active=2" class="link">
                    <div class="shouyi cashwait">¥{{list.cashwait}}</div>
                    待到账
                </router-link>
            </van-col>
            <van-col span="8">
                <router-link to="/cashout" class="link">
                    <div class="shouyi cancash">¥{{list.cancash}}</div>
                    可提现
                </router-link>
            </van-col>
        </van-row>

        
        <van-cell-group class="user-group">
            <van-cell icon="records" title="我的提现" is-link to="/mycashout" />
        </van-cell-group>

        <van-cell-group class="user-group">
            <van-cell icon="points" title="我的收益" is-link  to="/cashin" />
        </van-cell-group>

        <!-- <van-cell-group class="user-group">
            <van-cell icon="gold-coin-o" title="如何快速赚钱?" is-link />
        </van-cell-group> -->

        <AgentTabber active="1" />
  </div>
</template>

<script>
import AgentTabber from "@/components/tabbar/AgentTabber";
// import AgeDialog from "@/components/AgeDialog"
export default {
    data(){
        return{
            list:{
                allcash: "0.00",
                cancash: "0.00",
                cashwait: "0.00"
            }
        }
    },
    created(){
        axios.get(
            "my_cash"
        ).then(res => {
            if(res.code == 1){
                this.list = res.data
            }else{
               this.$toast(result.message);
            }
        })
    },
    components: {
        AgentTabber,
        // AgeDialog
    },
}
</script>

<style scoped>
    .user-group{
        padding: 15px 0;
        font-size: 12px;
        background-color: #fff;
        text-align: left;
    }
    .user-group >>>.van-icon {
        display: block;
        font-size: 24px;
    }
    .shouyi{
        display: block;
        font-size: 20px;
        font-weight: 700;
    }
    .allcash{
        color: #ee0a24;
    }
    .cancash{
        color: #ff976a;
    }
    .cashwait{
        color: #07c160;
    }
    .user-links{
        padding: 15px 0;
        font-size: 12px;
        background-color: #fff;
        text-align: center;
    }

    .van-cell-group{
        text-align: left;
    }
    .link{
        color: #323233;
    }
    >>>.van-field__label{
        font-size: 12px;
    }
    >>>.van-field__control{
        font-size: 12px;
    }
</style>